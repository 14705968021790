<template>
    <Setup-Step
        @yes="f => addNew()"
        @no="f => f()"
        :customizeTo="stage == 0 ? { name: 'order-slots' } : null"
        :loadingMsg="loadingMsg"
        :settings="settings"
        :stage.sync="stage"
        step="order-slots"
        title="Ordering Slots">
        <template v-slot:body>
            <v-tabs-items v-model="stage" touchless>
                <v-tab-item>
                    <BT-List-Endless
                        :canSearch="false"
                        :loadingMsg.sync="loadingMsg"
                        maxHeight="50vh"
                        navigation="order-slots"
                        :refreshToggle="refreshList">
                        <template v-slot="{ item }">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.slotName }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn small text @click="addNew(item)">change</v-btn>
                            </v-list-item-action>
                        </template>
                    </BT-List-Endless>
                    
                    <Setup-More :settings="settings">
                        <Setup-Example>
                            <p>Creative Crayons LTD only deliver to their customers on Tuesdays and Thursdays.  They also require that for an order to be delivered on Thursday it needs to be placed by Tuesday.</p>
                            <p>Colin will create one slot named 'Mon --> Tues' and another slot named 'Tues --> Thurs'.</p>
                        </Setup-Example>
                    </Setup-More>

                    <p class="my-4">Would you like to add a slot?</p>
                    
                </v-tab-item>

                <v-tab-item>
                    <p>Looks like this slot has a bit more complicated timing.  It's a bit more advanced, but here's the cron expression this slot uses:</p>

                    <BT-Field-String
                        v-model="newItem.cronExpression"
                        label="Cron Expression" />

                    <div class="my-4">
                        <a href="https://crontab.cronhub.io/" target="_blank" class="primary--text">Learn more about cron expressions</a>
                    </div>
                    
                    <BT-Btn
                        label="Customize"
                        class="primary--text"
                        inline
                        text
                        :to="{ name: 'order-slot', params: { id: newItem.id }}" />
                    
                    <BT-Btn 
                        buttonClass="primary mx-1"
                        inline
                        label="Done"
                        @click="save" />
                </v-tab-item>

                <v-tab-item>
                    <p>What day of the week does the order need to be placed by?</p>

                    <BT-Select-List-Box
                        @change="selectOrderWeekday"
                        :canDeselect="false"
                        v-model="orderWeekday"
                        height="50vh"
                        isEditing
                        isSelectingNullValue
                        :items="wkOptions"
                        itemText="text"
                        itemValue="value"
                        label="Day Of Week" />
                        
                    <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>

                <v-tab-item>
                    <p>What time of the day?</p>

                    <BT-Select-List-Box
                        @change="stage += 1"
                        :canDeselect="false"
                        v-model="orderTime"
                        height="50vh"
                        isEditing
                        isSelectingNullValue
                        :items="hourOptions"
                        itemText="text"
                        itemValue="value"
                        label="Time of Day" />
                    
                    <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>

                <v-tab-item>
                    <p>How many days later will delivery occur?</p>

                    <BT-Field-Number
                        @change="updateDeliveryWeekday"
                        v-model.number="deliveryDays"
                        label="Days Till Delivery"
                        isEditing />

                    <!--show day of week?-->
                    <p>Currently</p>
                    <ul v-if="orderWeekday != null">
                        <li>Order: {{ wkOptions[orderWeekday].text }}</li>
                        <li>Deliver: {{ deliveryWeekday }}</li>
                    </ul>
   
                    <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>

                <v-tab-item>
                    <p>Roughly what time on {{ deliveryWeekday }}?</p>

                    <BT-Select-List-Box
                        @change="suggestName"
                        :canDeselect="false"
                        v-model="deliveryTime"
                        height="50vh"
                        isEditing
                        isSelectingNullValue
                        :items="hourOptions"
                        itemText="text"
                        itemValue="value"
                        label="Time of Day" />
                        
                    <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Next"
                        @click="stage += 1" />
                </v-tab-item>

                <v-tab-item>
                    <BT-Field-String
                        label="Slot Name"
                        v-model="newItem.slotName"
                        isEditing />
                    <BT-Btn 
                        buttonClass="primary mx-1"
                        inline
                        label="Done"
                        @click="save" />
                </v-tab-item>
            </v-tabs-items>
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Order-Slot-Setup',
    components: {
        // BTCronSetup: () => import('~components/BT-Cron-Setup.vue'),
        // BTTime: () => import('~components/BT-Time.vue'),
        BTSelectListBox: () => import('~components/BT-Select-List-Box.vue'),
        SetupExample: () => import('~home/setup/Setup-Example.vue'),
        SetupMore: () => import('~home/setup/Setup-More.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue'),
    },
    data() {
        return {
            deliveryDays: 1,
            deliveryWeekday: null,
            deliveryTime: null,
            hourOptions: [
                { text: '12:00 am', value: 0 },
                { text: '1:00 am', value: 1 },
                { text: '2:00 am', value: 2 },
                { text: '3:00 am', value: 3 },
                { text: '4:00 am', value: 4 },
                { text: '5:00 am', value: 5 },
                { text: '6:00 am', value: 6 },
                { text: '7:00 am', value: 7 },
                { text: '8:00 am', value: 8 },
                { text: '9:00 am', value: 9 },
                { text: '10:00 am', value: 10 },
                { text: '11:00 am', value: 11 },
                { text: '12:00 pm', value: 12 },
                { text: '1:00 pm', value: 13 },
                { text: '2:00 pm', value: 14 },
                { text: '3:00 pm', value: 15 },
                { text: '4:00 pm', value: 16 },
                { text: '5:00 pm', value: 17 },
                { text: '6:00 pm', value: 18 },
                { text: '7:00 pm', value: 19 },
                { text: '8:00 pm', value: 20 },
                { text: '9:00 pm', value: 21 },
                { text: '10:00 pm', value: 22 },
                { text: '11:00 pm', value: 23 },
            ],
            isCustom: false,
            loadingMsg: null,
            newItem: {},
            
            orderTime: null,
            orderWeekday: null,
            
            refreshList: false,
            stage: 0,

            wkOptions: [
                { text: 'Sun', value: '0' },
                { text: 'Mon', value: '1' },
                { text: 'Tue', value: '2' },
                { text: 'Wed', value: '3' },
                { text: 'Thu', value: '4' },
                { text: 'Fri', value: '5' },
                { text: 'Sat', value: '6' }] //0-6
        }
    },
    props: {
        settings: { //{ currentIndex: 0, stepperPlan: [], currentStep: 0 }
            type: Object,
            default: null
        },
    },
    // computed: {
    //     wkOptions() {
    //         return this.weekdayOptions();
    //     }
    // },
    methods: {
        addNew(j) {
            this.isCustom = false;

            if (j == null) {
                this.orderWeekday = null;
                this.orderTime = null;

                this.deliveryWeekday = null;
                this.deliveryTime = null;
                this.deliveryDays = 1;

                this.newItem = {
                    id: null,
                    rowVersion: null,
                    slotName: null,
                    isOn: true,
                    cronExpression: null,
                    adjustments: null
                };
            }
            else {
                this.newItem = j;

                //try unpack
                var cronList = j.cronExpression.split(/\s+/);
console.log(cronList);
                if (cronList[0] != '0') {
                    this.isCustom = true;
                }

                if (this.hourOptions.some(x => x.value == cronList[1])) {
                    this.orderTime = Number.parseInt(cronList[1]);
                }
                else {
                    this.isCustom = true;
                }

                if (cronList[2] != '*' || cronList[3] != '*') {
                    this.isCustom = true;
                }

                if (this.wkOptions)
                if (this.wkOptions.some(x => x.value == cronList[4])) {
                    this.orderWeekday = Number.parseInt(cronList[4]);
                }
                else {
                    this.isCustom = true;
                }

                if (cronList.length == 6) {
                    var lead = cronList[5].split('-');
                    
                    if (lead.length == 1 || lead.length == 2 && lead[1] == '0') {
                        var deliveryHours = (Number.parseInt(lead[0]) / 60);
                        console.log(deliveryHours);
                        //get order day and time
                        var orderOn = this.$BlitzIt.auth.createRawTZ();

                        while (orderOn.hour != this.orderTime) {
                            orderOn = orderOn.plus({ hours: 1 });
                        }

                        while(orderOn.weekday != this.orderWeekday) {
                            orderOn = orderOn.plus({ days: 1 })
                        }
                        
                        var deliverOn = orderOn.plus({ hours: deliveryHours });
                        console.log(deliverOn);
                        this.deliveryWeekday = this.wkOptions[deliverOn.weekday].text;
                        this.deliveryTime = deliverOn.hour;
                        this.deliveryDays = Math.ceil(deliverOn.diff(orderOn, 'days').days);
                    }
                    else {
                        console.log('b')
                        this.isCustom = true;
                    }
                }
                else {
                    this.isCustom = true;
                }
            }

            if (this.isCustom) {
                this.stage += 1;
            }
            else {
                this.stage += 2;
            }
            
        },
        selectOrderWeekday() {
            this.updateDeliveryWeekday();
            this.stage += 1;
        },
        suggestName() {
            if (this.newItem.slotName == null) {
                this.newItem.slotName = this.wkOptions[this.orderWeekday].text + ' to ' + this.deliveryWeekday;
            }
            this.stage += 1;
        },
        async save() {
            try {
                this.loadingMsg = 'Saving';
                var res = null;

                //load here
                var hrDif = (this.deliveryTime - this.orderTime) + (this.deliveryDays * 24);
                var exp = `0 ${this.orderTime} * * ${this.orderWeekday} ${hrDif * 60}`;

                this.newItem.cronExpression = exp;

                if (this.newItem.id == null) {
                    res = await this.$BlitzIt.store.post('order-slots', this.newItem);
                    
                    //wait a couple seconds
                    await this.twiddleThumbs(2000);
                }
                else {
                    res = await this.$BlitzIt.store.patch('order-slots', this.newItem);
                }
                
                this.newItem.id = res.id;
                this.newItem.rowVersion = res.rowVersion;

                this.refreshList = !this.refreshList;
                this.stage = 0;
            }
            finally {
                this.loadingMsg = null;
            }
            
        },
        updateDeliveryWeekday() {
            var orderW = Number.parseInt(this.orderWeekday);
            var deliveryW = orderW + this.deliveryDays;

            while (deliveryW > 6) {
                deliveryW -= 7;
            }
            
            this.deliveryWeekday = this.wkOptions[deliveryW].text;
        }
    }
}
</script>